import type { ComponentProps } from "react";

import LogoIcon from "!@svgr/webpack!../images/logo.svg?react";
import analytics from "@goalteller/app-kit/tools/analytics";
import { useThemeState } from "@goalteller/app-kit/wo-library/contexts/ThemeContext";
import { Anchor } from "@goalteller/app-kit/ye-design/components/atoms";
import {
  TopNavItem,
  TopNav as YeTopNav,
} from "@goalteller/app-kit/ye-design/components/structures";
import {
  useLayoutMethods,
  useLayoutState,
} from "@goalteller/app-kit/ye-design/contexts/LayoutContext";
import { getDynamicClassName } from "@goalteller/app-kit/ye-design/tools/utils.js";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

import newBadge from "../images/newBadge.svg";
import newmiImg from "../images/newmi-logo.webp";
import actionArrow from "../images/tiltArrow.svg";
import { GradientButton } from "../wrappers/button";
import AmpImage from "./AmpImage";
import styles from "./topNav.module.css";

const ANALYTICS_POSITION = "top navigation";

function TopNavAnchor(props: ComponentProps<typeof Anchor>) {
  const layoutState = useLayoutState();
  const {
    dispatch: { updateTopNav },
  } = useLayoutMethods();

  return (
    <Anchor
      as={Link}
      onClick={() => {
        analytics.track("Navigation", {
          position: ANALYTICS_POSITION,
          text: props.children,
        });
        updateTopNav({
          isDrawerToggled: !layoutState.topNav.isDrawerToggled,
        });
      }}
      size="small"
      {...props}
    />
  );
}

function Logo({ isCorporate }: { isCorporate: boolean }) {
  const { pathname } = useRouter();

  return (
    <Link
      className={styles.logo}
      href={isCorporate ? pathname : "/"}
      onClick={() => {
        analytics.track("Navigation", {
          position: ANALYTICS_POSITION,
          text: "logo",
        });
      }}
    >
      {pathname.includes("newmi") ? (
        <div className={styles.newmiLogoContainer}>
          <LogoIcon />
          <div className={styles.divider} />
          <AmpImage
            alt="newmi"
            className={styles.newmiLogo}
            height={48}
            placeholder="blur"
            src={newmiImg}
          />
        </div>
      ) : (
        <LogoIcon className={styles.logoImage} />
      )}
    </Link>
  );
}

function SignInButton() {
  return (
    <GradientButton
      onClick={() => {
        window.open(
          `${process.env.NEXT_PUBLIC_WEBAPP_GOALTELLER}/login`,
          "_blank",
        );
      }}
    >
      Sign In{"  "}
      <span className={styles.arrowIcon}>
        <Image alt="arrow" src={actionArrow} />
      </span>
    </GradientButton>
  );
}

function ContentLeft() {
  const router = useRouter();

  return (
    <div className={styles.rightWrapper}>
      <div className={styles.menu}>
        <TopNavItem className={styles.menuWrapper}>
          <TopNavAnchor
            className={clsx(styles.link, {
              [styles.activeTab]: router.pathname === "/",
            })}
            href="/"
          >
            Home
          </TopNavAnchor>
        </TopNavItem>

        <TopNavItem className={styles.menuWrapper}>
          <TopNavAnchor
            className={clsx(styles.link, {
              [styles.activeTab]: router.pathname === "/about",
            })}
            href="/about"
          >
            About us
          </TopNavAnchor>
        </TopNavItem>

        <TopNavItem className={styles.newBadgeWrapper}>
          <TopNavAnchor
            className={clsx(styles.link, {
              [styles.activeTab]: router.pathname === "/features",
            })}
            href="/features"
          >
            Free Tools
          </TopNavAnchor>
          <AmpImage
            alt="new quick solutions badge"
            className={styles.newBadge}
            src={newBadge}
            width={37}
          />
        </TopNavItem>

        <TopNavItem className={styles.newBadgeWrapper}>
          <TopNavAnchor
            className={clsx(styles.link, {
              [styles.activeTab]: router.pathname === "/features",
            })}
            href="/product/explorer"
          >
            Product
          </TopNavAnchor>
          <AmpImage
            alt="new quick solutions badge"
            className={styles.newBadge}
            src={newBadge}
            width={37}
          />
        </TopNavItem>

        <TopNavItem>
          <TopNavAnchor
            className={clsx(styles.link, {
              [styles.activeTab]: router.pathname === "/pricing-plan",
            })}
            href="/pricing-plan"
          >
            Pricing
          </TopNavAnchor>
        </TopNavItem>

        <TopNavItem>
          <TopNavAnchor
            className={clsx(styles.link, {
              [styles.activeTab]: router.pathname === "/blog",
            })}
            href="/blog"
          >
            Blog
          </TopNavAnchor>
        </TopNavItem>
      </div>
    </div>
  );
}

function CoporateContentRight() {
  const { pathname } = useRouter();

  return (
    <div className={styles.right}>
      <Link
        className={styles.hashLink}
        href={`${pathname}#home`}
        scroll={false}
      >
        Home
      </Link>
      <Link
        className={styles.hashLink}
        href={`${pathname}#about`}
        scroll={false}
      >
        About Us
      </Link>
      <Link
        className={styles.hashLink}
        href={`${pathname}#pricing`}
        scroll={false}
      >
        Pricing
      </Link>
      <Link
        className={clsx(styles.button, styles.hashButton)}
        href={`${pathname}#email`}
        scroll={false}
      >
        Free Trial
      </Link>
    </div>
  );
}

export default function TopNav({ ...props }) {
  const { sectionThemes } = useThemeState();
  const { pathname } = useRouter();
  const isCorporate = pathname.includes("corporate");

  return (
    <YeTopNav
      className={clsx(
        styles.root,
        sectionThemes.hero &&
          getDynamicClassName(styles, `theme-hero-${sectionThemes.hero}`),
      )}
      contentMiddle={<ContentLeft />}
      contentRight={isCorporate ? <CoporateContentRight /> : <SignInButton />}
      innerClassNames={{
        container: styles.container,
        contentMenu: styles.contentMenu,
        isExpanded: styles.isExpanded,
      }}
      logo={<Logo isCorporate={isCorporate} />}
      sticky={{ hideOnScroll: false, shrinkOffset: 100 }}
      variant="flat"
      {...props}
    />
  );
}
