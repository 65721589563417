import { Button } from "@goalteller/app-kit/ye-design/components/atoms";
import generateCssWrapper from "@goalteller/app-kit/ye-design/wrappers/generateCssWrapper";
import clsx from "clsx";

import styles from "./button.module.css";

export const FormFilledButton = generateCssWrapper(
  clsx(styles.form, styles.filled),
  Button,
);
export const FormButton = generateCssWrapper(styles.form, Button);

export const PrimaryOutlineButton = generateCssWrapper(
  styles.primaryOutline,
  Button,
);

export const GradientButton = generateCssWrapper(styles.gradientButton, Button);

export const PricingBannerButton = generateCssWrapper(
  styles.pricingButton,
  Button,
);
