import { useAmp } from "next/amp";
import Image from "next/image";
import { useLayoutEffect, useRef, useState } from "react";
import { useWindowSize } from "react-use";

export const config = { amp: "hybrid" };

interface Props extends React.ComponentProps<typeof Image> {
  autoHeight?: boolean;
}

export default function AmpImage({ autoHeight = true, ...props }: Props) {
  const { height } = props;
  const isAmp = useAmp();
  const ref = useRef<HTMLImageElement>();
  const [naturalHeight, setNaturalHeight] = useState<number>();
  const { width } = useWindowSize();

  useLayoutEffect(() => {
    if (ref.current) {
      setNaturalHeight(
        ref.current.naturalHeight *
          (ref.current.naturalWidth / ref.current.offsetWidth),
      );
    }
  }, [width]);

  const Element = isAmp ? "amp-img" : Image;

  return (
    <Element
      height={autoHeight ? naturalHeight : height}
      // @ts-expect-error 2322 because ref for amp-img is not defined
      ref={ref}
      {...props}
    />
  );
}
