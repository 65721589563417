import MaxWidthContainer from "@goalteller/app-kit/components/MaxWidthContainer";
import { useLocalStorage } from "react-use";

import { WARNING_MESSAGE } from "../data/utility";
import warning from "../images/warningWhiteBg.svg";
import AmpImage from "./AmpImage";
import WarningButton from "./WarningButton";
import styles from "./warningTape.module.css";

function WarningTape() {
  const [open, setOpen] = useLocalStorage("GtStore.warningTapelView", true, {
    deserializer: (value: string) => value === "true",
    raw: false,
    serializer: (value: boolean) => (value ? "true" : "false"),
  });

  return (
    open && (
      <div className={styles.tape}>
        <MaxWidthContainer className={styles.tapeContainer}>
          <div className={styles.text}>
            <AmpImage alt="warning" className={styles.img} src={warning} />
            {WARNING_MESSAGE}
          </div>
          <WarningButton className={styles.button} setOpen={setOpen} />
        </MaxWidthContainer>
      </div>
    )
  );
}

export default WarningTape;
