import { Button } from "@goalteller/app-kit/ye-design/components/atoms";
import { IconX } from "@tabler/icons-react";

function WarningButton({
  className,
  setOpen,
}: {
  className: string;
  setOpen: (open: boolean) => void;
}) {
  return (
    <Button
      className={className}
      onClick={() => {
        setOpen(false);
      }}
    >
      <IconX />
    </Button>
  );
}

export default WarningButton;
