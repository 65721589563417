/* jscpd:ignore-start */
import "@goalteller/app-kit/config/polyfills";

import "../styles/globals.css";
// above imports have to be in order

import type { AppProps } from "next/app";

import Footer from "@goalteller/app-kit/components/Footer/NxFooter";
import HeadContent from "@goalteller/app-kit/pages/HeadContent";
import NextAppWrapper from "@goalteller/app-kit/pages/NextAppWrapper";
import getGtmScript from "@goalteller/app-kit/scripts/gtm";
import { Page } from "@goalteller/app-kit/ye-design/components/structures";
import clsx from "clsx";
import { Kumbh_Sans } from "next/font/google";
import Head from "next/head";
import Script from "next/script";
import "react-toastify/dist/ReactToastify.css";

import TopNav from "../components/TopNav";
import WarningTape from "../components/WarningTape";
import "../integrations";
/* jscpd:ignore-end */

const bodyFont = Kumbh_Sans({
  subsets: ["latin"],
  variable: "--ye-font-family-body",
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function App({ Component, pageProps }: AppProps) {
  // TODO: implement google analytics using gtag from example at
  // https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_app.js

  return (
    <>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style global jsx>{`
        :root {
          --ye-font-family-body: ${bodyFont.style.fontFamily};
        }
      `}</style>
      <Head>
        <HeadContent
          discription="GoalTeller aids you in planning for yours and your loved ones future. Make the best investment decisions that help you achieve your goals"
          env={process.env.NEXT_PUBLIC_ENV}
          siteDomain="goalteller.com"
          siteName="GoalTeller"
          title="Goalteller - Financial planning like never before"
        />
      </Head>
      {/* Google Tag Manager */}
      <Script id="google-tag-manager" strategy="lazyOnload">
        {!!process.env.NEXT_PUBLIC_GTM_ENV &&
          getGtmScript({
            gtmEnv: process.env.NEXT_PUBLIC_GTM_ENV,
            gtmId: "GTM-5PQXHMR",
          })}
      </Script>
      {/* End Google Tag Manager */}
      <NextAppWrapper storeVersion={1.2}>
        <div className={clsx(bodyFont.variable)}>
          <WarningTape />
          <Page topNav={<TopNav />}>
            <Component {...pageProps} />
            <Footer />
          </Page>
        </div>
      </NextAppWrapper>
    </>
  );
}
